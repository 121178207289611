<template>
  <div>
    <apexchart
      width="100%"
      type="line"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
export default {
  props: {
    date: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          categories: this.date,
        },
      },
      series: [
        {
          name: 'series-1',
          data: this.value,
        },
      ],
    }
  },
}
</script>
